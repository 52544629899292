.menu-principal,
.menu-secundario {
  &__nav {
    display: flex;
    align-items: center;
    margin: auto 0;
    list-style: none;

    .menu-item {
      margin-right: 1.2rem;
      &:nth-last-child(1) {
        margin-right: 0.6rem;
      }
      a {
        text-decoration: none;
        color: $color-texto;
        font-size: 1rem;
      }
    }
  }
}

.menu-principal {
  @media screen and (max-width: 1150px) {
    display: none;
  }
}

.ubermenu {
  @media screen and (max-width: 1150px) {
    display: none !important;
  }
}

.menu-secundario {
  @media screen and (max-width: 1150px) {
    display: none;
  }
}

.menu-contacto {
  &__item {
    display: flex;
    text-decoration: none;
    margin: 0 0.5rem;
    width: 40px;
    height: 40px;
    @media screen and (max-width: 1440px) {
      margin: 0 0.3rem;
    }
    @media screen and (max-width: 1280px) {
      margin: 0 0.25rem;
    }
    @media screen and (max-width: 575px) {
      margin: 0 0.2rem;
    }
  }
}

.wpml-ls-slot-wpml-header {
  .wpml-ls-link {
    display: inline-flex;
    padding: 2px;
    //border: 3px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    //margin-left: 5px;
    .wpml-ls-flag {
      border-radius: 4px;
      width: 24px;
      height: auto;
      border: 1px solid $color-gris-02;
    }
  }
}

.ubermenu .ubermenu-item .ubermenu-target {
  text-decoration: none;
  color: $color-texto !important;
  transition: $trans;
  &:hover {
    color: $color-principal !important;
    transition: $trans;
  }
  @media screen and (max-width: 1440px) {
    padding: 15px 10px;
  }
  @media screen and (max-width: 1280px) {
    padding: 15px 8px;
  }
}

.ubermenu-submenu {
  background-color: $color-background !important;
}

.ubermenu .ubermenu-submenu-drop.ubermenu-submenu-align-full_width {
  //width: 100% !important;
  width: max-content !important;
  background-color: rgba($color-blanco, $alpha: 1) !important;
  position: absolute !important;
  top: 100% !important;
  margin-top: 0px !important;
  left: auto !important;
  display: flex !important;
  flex-direction: column !important;
  .ubermenu-target {
    color: $color-texto !important;
    &:hover {
      background-color: $color-dark !important;
      color: $color-blanco !important;
    }
  }
}

.ubermenu #menu-item-842 a,
.ubermenu #menu-item-2701 a,
.ubermenu #menu-item-2893 a,
.ubermenu #menu-item-3705 a,
.ubermenu #menu-item-3781 a,
.ubermenu #menu-item-3780 a {
  background-color: $color-secundario;
  color: $color-blanco !important;
  text-transform: uppercase;
  &:hover {
    background-color: darken(
      $color: $color-secundario,
      $amount: 10%
    ) !important;
    color: $color-blanco !important;
  }
}

.menu-item-3134 a,
.menu-item-3136 a,
.menu-item-3706 a,
.menu-item-3785 a,
.menu-item-3783 a {
  background-color: $color-secundario;
  color: $color-blanco !important;
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  &:hover {
    background-color: darken(
      $color: $color-secundario,
      $amount: 10%
    ) !important;
    color: $color-blanco !important;
  }
  // @media screen and (max-width: 1150px) {
  //   display: none;
  // }
}

.menu-overlay__menu--2 {
  .menu-item-3134 a,
  .menu-item-3136 a,
  .menu-item-3706 a,
  .menu-item-3785 a,
  .menu-item-3783 a {
    background-color: $color-blanco;
    color: $color-dark !important;
    text-transform: uppercase;
    padding: 0.4rem 0.8rem;
    border-radius: 4px;
    &:hover {
      background-color: darken($color: $color-blanco, $amount: 5%) !important;
      color: $color-dark !important;
    }
  }
}

.menu-item-3135 a {
  display: none;
}

// .menu-item-4018 a svg,
// .menu-item-4019 a svg,
// .menu-item-4020 a svg,
// .menu-item-4021 a svg,
// .menu-item-4022 a svg,
// .menu-item-4023 a svg {
//   width: 34px;
//   height: auto;
// }

.menu-overlay__nav {
  a {
    text-decoration: none;
    color: $color-texto !important;
    transition: $trans;
    &:hover {
      color: $color-principal !important;
      transition: $trans;
    }
  }
}
