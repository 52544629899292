.motor {
  padding: 0.5rem;
  margin: 0 auto;
  box-shadow: $box-shadow;
  background-color: $color-blanco;
  position: relative;
  z-index: 99;
  border-radius: 4px;
  .form-control,
  .form-select,
  .boton {
    padding: 0.8rem 0.5rem;
    font-size: 0.8rem;
    @media screen and (max-width: 575px) {
      padding: 0.5rem 0.5rem;
    }
  }

  .form-control[readonly] {
    background-color: $color-background;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr max-content max-content 180px 1fr;
    gap: 1rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
      gap: 0.3rem;
    }
  }
  &__item {
    &--checkin {
    }

    &--checkout {
    }

    &--adultos {
      @media screen and (max-width: 991px) {
        grid-column: 1 / 3;
      }
    }

    &--ninos {
    }

    &--codigo {
    }

    &--submit {
      @media screen and (max-width: 991px) {
        grid-column: 1 / 3;
      }
      .boton {
        width: 100%;
        font-weight: bold;
      }
    }
  }

  &__input {
    background-position: left 11px top 16px !important;
    background-size: 18px 18px !important;
    padding-left: 2.5rem !important;
    @media screen and (max-width: 991px) {
      background-position: left 11px top 14px !important;
    }
    @media screen and (max-width: 575px) {
      background-position: left 11px top 9px !important;
    }
    &--date {
      background: url(../assets/ico/ico-cal.svg) no-repeat;
    }
    &--promo {
      background: url(../assets/ico/ico-badge.svg) no-repeat;
    }
  }

  &__descripcion {
    margin-bottom: 1rem;
  }
}

.header-motor {
  .motor {
    box-shadow: none;
    padding: 0;
    margin: 0 1rem;
    background-color: transparent;
    .motor__content {
      @media screen and (max-width: 1440px) {
        gap: 0.3rem;
      }
      @media screen and (max-width: 1280px) {
        gap: 0.3rem;
      }
    }
    .form-control,
    .form-select,
    .boton {
      padding: 0.6rem 0.5rem;
      font-size: 0.8rem;
      @media screen and (max-width: 575px) {
        padding: 0.5rem 0.5rem;
      }
    }
    &__input {
      background-position: left 11px top 10px !important;
      background-size: 18px 18px !important;
      padding-left: 2.5rem !important;
      @media screen and (max-width: 991px) {
        background-position: left 11px top 11px !important;
      }
      &--date {
        background: url(../assets/ico/ico-cal.svg) no-repeat;
      }
      &--promo {
        background: url(../assets/ico/ico-badge.svg) no-repeat;
      }
    }
    .motor__input--promo {
      background-color: #ffffff;
    }
  }
}

.motor-ofertas {
  background-color: $color-background;
  padding: 2rem;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  margin-bottom: 4rem;
  @media screen and (min-width: 768px) {
    position: sticky;
    top: 7rem;
  }
  &__descripcion {
  }
  &__titulo {
    font-size: 1.2rem;
    font-weight: bold;
    color: $color-dark;
    margin-bottom: 1.5rem;
  }
  &__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  &__input-group {
    label {
      width: 50%;
      font-size: 0.85rem;
      margin-bottom: 0;
      font-weight: bold;
    }
  }
  &__input {
    background-position: left 11px top 10px !important;
    background-size: 18px 18px !important;
    padding-left: 2.5rem !important;
    @media screen and (max-width: 991px) {
      background-position: left 11px top 11px !important;
    }
    &--date {
      background: url("../assets/ico/ico-cal.svg") no-repeat;
    }
    &--promo {
      background: url("../assets/ico/ico-badge.svg") no-repeat;
    }
  }
}

.motor-ofertas-tags {
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__item {
    background-color: $color-dark;
    color: $color-background !important;
    border-radius: 50px;
    padding: 0.2rem 0.8rem;
    font-size: 0.9rem;
    &--dia {
      background-color: #ff0066;
    }
    &--noches {
    }
  }
}
