.blog {
  &__categorias {
    display: flex;
    justify-content: center;
    text-align: center;
    align-self: center;
    margin-bottom: 2.5rem;
    .widget__title {
      margin-bottom: 1rem;
      font-family: $typo-alternativa;
    }
    ul {
      @include lista-tags;
      justify-content: center;
    }
  }

  &__categoria {
    margin-top: 1rem;
    line-height: 1.3;
    margin-bottom: 5px;
    a {
      text-transform: uppercase;
      text-decoration: none;
      font-size: $font-size-small;
      color: $color-alternativo;
      font-weight: 600;
      margin-bottom: 0.5rem;
      &:hover {
        color: darken($color-alternativo, 5%);
      }
    }
  }

  &__listado {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5rem 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 3rem 2rem;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 4rem;
    }
  }

  &__navigation {
    padding: 3rem 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    grid-column: 1 / span 3;
    @media screen and (max-width: 991px) {
      grid-column: 1 / span 2;
    }
    @media screen and (max-width: 767px) {
      grid-column: 1 / span 1;
    }
    a {
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px;
      font-size: 0.875rem;
      color: $color-principal;
      font-weight: 600;
      margin-bottom: 0.5rem;
      &:hover {
        color: darken($color-principal, 5%);
      }
    }
  }

  &__alignleft {
  }

  &__alignright {
  }
}

.blog-listado {
  &__thumb {
    margin-bottom: 1rem;
    aspect-ratio: 16 / 9;
    position: relative;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
      box-shadow: $box-shadow-small;
    }
  }
  &__titulo {
    a {
      font-size: $header-3;
      color: $color-principal;
      font-weight: $font-weight-bold;
      transition: $trans;
      &:hover {
        color: $color-dark;
        transition: $trans;
      }
    }
  }
  &__categoria {
    color: $color-alternativo;
    a {
      font-size: $font-size-small;
      color: $color-alternativo;
      text-transform: uppercase;
      &:hover {
        color: $color-secundario;
      }
    }
  }
  &__excerpt {
    font-size: $font-size-small;
  }
}

.entrada {
  &__container {
    display: grid;
    grid-template-columns: 5% 65% 30%;
    gap: 0;
    @media screen and (max-width: 991px) {
      grid-template-columns: 8% 92%;
    }
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__content {
  }
  .post__cat {
    margin-top: 0;
    margin-bottom: 1.25rem;
    a{
      padding: .5rem .5rem .5rem 0;
      
    }
  }
  .post__title a{
    padding: .5rem .5rem .5rem 0;
  }
}
.post {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  &__thumbnail {
    width: 100%;
    img {
      max-width: 100%;
      height: auto;
      max-height: 600px;
      object-fit: cover;
    }
    margin-bottom: 1rem;
  }
  &__cat {
    margin-top: 1rem;
    a {
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px;
      font-size: 1rem;
      color: $color-principal;
      font-weight: 600;
      margin-bottom: 0.5rem;
      &:hover {
        color: darken($color-principal, 5%);
      }
    }
  }

  &__title {
    a {
      font-size: 3.1875rem;
      font-weight: bold;
      letter-spacing: 1.5px;
      color: $color-gris-04;
      line-height: 1;
      text-decoration: none;
      font-family: $typo-alternativa;
      @media screen and (max-width: 767px) {
        font-size: 2.5rem;
        line-height: 1.2;
      }
    }
  }

  &__date {
    font-size: 1rem;
    font-weight: 500;
    color: $color-gris-02;
    padding-top: 1rem;
  }

  &__entry {
    font-size: 1rem;
    font-weight: 400;
    color: $color-gris-04;
    padding-top: 1rem;
    a {
      color: $color-principal;
      &:hover {
        color: $color-dark;
      }
    }
    .a-img {
      display: flex;
      justify-content: center;
    }
    .figure-img {
      display: flex;
      justify-content: center;
    }
    h1,
    h2,
    h3,
    h4 {
      font-family: $typo-alternativa;
    }
  }

  &__navigation {
    padding: 3rem 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    a {
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1px;
      font-size: 0.875rem;
      color: $color-principal;
      font-weight: 600;
      margin-bottom: 0.5rem;
      &:hover {
        color: darken($color-principal, 5%);
      }
    }
  }

  &__alignleft {
  }
  &__alignright {
  }
  &__aside {
    .widget__title {
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: 1.5px;
      color: $color-gris-04;
      line-height: 1;
      text-decoration: none;
      font-family: $typo-alternativa;
    }
    ul {
      @include lista-tags-vertical;
    }
    &--left {
      @media screen and (max-width: 575px) {
        align-self: flex-end;
      }
    }
    &--right {
      margin-left: 3rem;
      @media screen and (max-width: 1280px) {
        margin-left: 2rem;
      }
      @media screen and (max-width: 991px) {
        grid-column: 1 / span 2;
        margin-left: 8%;
      }
      @media screen and (max-width: 575px) {
        margin-left: 0;
      }
    }
    .social-icon {
      font-size: 24px !important;
      background-color: $color-dark !important;
      &:hover {
        background-color: $color-principal !important;
      }
    }
  }
  &__left {
    a {
      display: block;
    }
  }

  &__gallery {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    gap: 1.1875rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 2px;
    }
  }
  &__gallery-item {
    // &:nth-child(3) {
    //   @media screen and (min-width: 992px) {
    //     grid-column: 3 / 5;
    //     grid-row: 1 / 3;
    //   }
    // }

    // &:nth-child(10) {
    //   @media screen and (min-width: 992px) {
    //     grid-column: 2 / span 2;
    //     grid-row: 3 / span 2;
    //   }
    // }
  }
  &__gallery-img {
    width: 100%;
    height: auto;
    object-fit: cover;
    @media screen and (max-width: 767px) {
      margin-bottom: 2px;
    }
  }

  &__archivo-item {
    display: flex;
  }
  &__archivo-titulo {
    margin-right: 1rem;
    align-self: center;
  }
}

.blog__fixed {
  &--top {
    margin-bottom: 6.5rem;
    .post {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 3rem;
      &__left {
        align-self: center;
      }
      &__right {
        align-self: center;
      }
    }
    .blog__titulo a {
      font-size: 3.75rem;
      line-height: 1.4;
    }
    .blog__categoria a {
      font-size: 1rem;
    }
  }
  &--side {
    margin-bottom: 3.6875rem;
    .blog__titulo a {
      font-size: 2rem;
      line-height: 1.1;
    }
  }
  .blog__resumen {
    font-size: 1rem;
  }
}

.blog__fixed--mobile {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  .post {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 2rem;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    &__right {
      align-self: center;
      margin-left: 2rem;
      @media screen and (max-width: 575px) {
        margin-left: 0;
      }
    }
    &--inverted {
      .post__left {
        order: 2;
        @media screen and (max-width: 575px) {
          order: 1;
        }
      }
      .post__right {
        order: 1;
        margin-left: 0;
        margin-right: 2rem;
        @media screen and (max-width: 575px) {
          margin-right: 0;
          order: 2;
        }
      }
    }
  }
  .blog__titulo a {
    font-size: 2rem;
    line-height: 1.2;
    @media screen and (max-width: 575px) {
      font-size: 1.6rem;
      line-height: 1.1;
    }
  }
}

.blog__left {
  .blog__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .blog__entradas {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
  .post {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 3rem;
    margin-bottom: 2.5rem;
    &__left {
      align-self: center;
    }
    &__right {
      align-self: center;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .blog__categoria {
    margin-top: 0;
  }
}

.blog__right {
  .post {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
}

.share-post {
  position: sticky;
  top: 6rem;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }
  @media screen and (max-width: 575px) {
    flex-direction: row;
    align-items: center;
  }
  &__item {
    margin-bottom: 1rem;
    @media screen and (max-width: 991px) {
      margin-right: 0.75rem;
      margin-bottom: 0;
    }
  }

  &__logo {
    width: 48px;
    height: auto;
    margin-bottom: 6px;
    transition: $trans;
    fill: $color-dark !important;
    @media screen and (max-width: 991px) {
      width: 42px;
    }
    @media screen and (max-width: 575px) {
      width: 36px;
      margin-bottom: 1rem;
    }

    &:hover {
      transform: scale(1.2) rotate(360);
      transition: $trans;
      fill: $color-principal !important;
    }
  }
}

.category .blog__navigation {
  grid-column: 1 / span 3;
}
