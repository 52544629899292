.g-ofertas {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;

  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
  &__item {
    background-color: $color-background;
    box-shadow: $box-shadow;
    text-align: center;
    border-radius: 1rem;
    overflow: hidden;
  }
  &__item img {
    //border-radius: 1rem 1rem 0 0;
  }
  &__content {
    padding: 2rem;
    .motor-ofertas-tags {
      justify-content: center;
      gap: 5px;
    }
  }
  &__titulo {
    font-size: 1.3rem;
    font-weight: bold;
    color: $color-principal;
  }

  &__fechas {
    margin-bottom: 1rem;
  }
  &__fechas span {
    font-size: 0.85rem;
    font-weight: bold;
  }

  &__precio span {
    padding: 5px 15px 4px;
    border-radius: 0px;
    font-weight: bold;
    display: inline-block;
  }
  &__precio span sup {
    font-weight: normal;
  }

  &__precio-dia {
    background-color: $color-principal;
    color: #fff;
  }
  &__precio-noches {
    background-color: $color-dark;
    color: #fff;
  }

  &__descripcion {
    margin-top: 1rem;
    font-size: 1rem;
  }

  &__motor-reserva {
  }

  &__ver-oferta {
    font-size: 0.75rem;
  }

  &__lugar,
  &__horario {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.875rem;
    border: 0;
    text-decoration: none;
    padding: 0.3rem 0.7rem;
    border-radius: 0.25rem;
    box-shadow: $box-shadow;
    color: $color-blanco;
  }

  &__lugar {
    background-color: $color-principal;
  }

  &__horario {
    background-color: $color-dark;
  }
}

.g-ofertas--out {
  img.wp-post-image {
    filter: grayscale(100%);
    opacity: 0.5;
  }
}

.s-oferta {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 3rem;
  @media (max-width: 991px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .motor__input {
    margin-bottom: 0.75rem;
  }
}

.galeria-oferta {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  gap: 1rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 2px;
  }

  &__item {
    &:nth-child(5) {
      @media screen and (min-width: 992px) {
        grid-column: 2 / span 2;
        grid-row: 1 / span 2;
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        grid-column: 1 / span 2;
        grid-row: 3 / span 2;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
