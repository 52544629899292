.page-title {
  background-color: $color-background-light;
  padding: 10rem 0 2rem 0;
  @media screen and (max-width: 575px) {
    padding-top: 7rem;
  }
  &__h {
    font-size: 2.2rem;
    font-weight: bold;
    font-family: $typo-alternativa;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
      margin-bottom: 0.1rem;
    }
  }
  &__breadcrumbs {
    text-transform: uppercase;
    font-size: $font-size-small;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
      text-transform: none;
    }
    a {
      color: $color-principal;
      text-decoration: none;
      font-weight: 500;
    }
  }
  &__motor {
    margin-top: 1.5rem;
    max-width: 1320px;
  }
}

.single-oferta .page-title {
  //padding-bottom: 7.5rem;
  .page-title__motor {
    display: none;
  }
  @media screen and (max-width: 991px) {
    padding-bottom: 2.5rem;
  }
}
