$color-principal: #007aae;
$color-secundario: #ffc543;

$color-alternativo: #ff9500;
$color-dark: #00293b;
$color-texto: #000000;

$color-gris-01: rgba(0, 0, 0, 0.1);
$color-gris-02: rgba(0, 0, 0, 0.3);
$color-gris-03: rgba(0, 0, 0, 0.6);
$color-gris-04: rgba(0, 0, 0, 0.9);

$color-gris-05: rgba(255, 255, 255, 0.8);

$color-background: rgba(255, 255, 255, 1);
$color-background-light: #f2f7f8;
$color-blanco: #ffffff;
$color-negro: #000000;

$font-size-bigger: 1.5rem;
$font-size-big: 1.2rem;
$font-size: 1rem;
$font-size-small: 0.8rem;
$font-size-smaller: 0.7rem;

$header-1: 3rem;
$header-1-res: 2.18rem;
$header-2: 2.1875rem;
$header-2-res: 1.875rem;
$header-3: 1.5rem;
$header-3-res: 1.25rem;
$header-4: 1.25rem;
$header-5: 1rem;
$header-6: 0.87rem;

$font-weight: 400;
$font-weight-bold: 600;

$box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
$box-shadow-small: 0px 0px 15px rgba(0, 0, 0, 0.1);

$border-radius: $font-size / 2;

$trans: 0.3s all ease-in-out;
$trans-2: 0.5s all ease-in-out;

$sp-05: 1rem;
$sp-1: 2rem;
$sp-2: 6rem;
$sp-3: 9rem;

$typo-principal: "Helvetica Neue", Helvetica, Arial, sans-serif;
$typo-secundaria: "Georgia", serif;
$typo-alternativa: "M PLUS Rounded 1c", sans-serif;
