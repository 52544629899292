.mailchimp-formulario #mc_embed_signup {
  clear: left;
  font-size: 14px;
  font-family: $typo-principal;
  width: 100%;
}

.mailchimp-formulario #mc-embedded-subscribe-form {
  margin: 0;
}

.indicates-required {
  color: $color-blanco;
}

.mailchimp-formulario #mc_embed_signup {
  max-width: 400px;
}
.mailchimp-formulario #mc_embed_signup .mc-field-group {
  padding-bottom: 1rem;
  min-height: 0px;
  &--nombre {
    padding-bottom: 0;
  }
}

.mailchimp-formulario #mc_embed_signup .mailchimp-formulario__titulo {
  color: $color-secundario;
  text-transform: uppercase;
  font-size: 1rem;
}

.mailchimp-formulario #mc-embedded-subscribe {
  margin: 0;
  border-color: $color-secundario;
  border-radius: 4px;
}
