.footer {
  background-color: $color-dark;
  padding: 6rem 0;
  @media screen and (max-width: 767px) {
    padding: 4rem 0;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 3rem;
    align-items: flex-start;
    @media screen and (max-width: 1280px) {
      gap: 2rem;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 3rem 2rem;
      .footer__item--logo {
        order: 1;
      }
      .footer__item--newsletter {
        order: 3;
        grid-column: 1 / span 2;
        .form-check {
          text-align: left;
        }
      }
      .footer__item--rs {
        order: 2;
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 0;
      align-items: center;
      text-align: center;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    @media screen and (max-width: 991px) {
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 2rem;
      .footer__item--newsletter {
        margin-bottom: 0;
      }
    }
    .widget__title {
      font-size: 1rem;
      color: $color-secundario;
      text-transform: uppercase;
    }
    .menu {
      list-style: none;
      padding-left: 0;
      a {
        font-size: 1rem;
        color: $color-blanco;
        text-decoration: none;
        &:hover {
          color: $color-gris-05 !important;
        }
      }
    }
    &.mvl {
      margin-top: 1.5rem;
      .zoom-social-icons-list {
        margin-left: 0 !important;
      }
      @media screen and (max-width: 767px) {
        .widget__title {
          display: none;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        margin-top: 0;
      }
    }
  }

  &__item--logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > img {
      filter: brightness(0%) invert(1);
      max-width: 15rem;
      @media screen and (max-width: 767px) {
        width: 240px;
      }
    }
  }

  &__item--rs {
    .zoom-social-icons-list {
      margin-left: 0 !important;
      display: flex;
      justify-content: center;
    }
    .social-icon {
      font-size: 24px !important;
      color: $color-blanco !important;
      @media screen and (max-width: 767px) {
        font-size: 32px !important;
      }
      &:hover {
        color: $color-gris-05 !important;
      }
    }
  }

  &__item--hotel {
  }

  &__item--legal {
  }
  &__cv {
    margin-top: 1.5rem;
    max-width: 15rem !important;
    @media screen and (max-width: 767px) {
      width: 240px !important;
    }
    img {
      border-radius: 4px;
    }
  }
}

.cert {
  padding: 1.5rem 0;

  &__logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__logo {
    margin: 0 10px 10px 10px;
    img {
      max-width: 100px;
      max-height: 40px;
      filter: grayscale(1);
      opacity: 0.7;
      @media screen and (max-width: 575px) {
        max-width: 80px;
        max-height: 30px;
      }
    }
    &:nth-child(3) {
      img {
        max-height: 70px;
      }
    }
    &:nth-child(4) {
      img {
        max-height: 70px;
        max-width: 120px;
      }
    }
  }
}

.copyright {
  padding: 1.5rem 0;
  @media screen and (max-width: 991px) {
    font-size: 0.9rem;
  }
  a {
    text-decoration: none;
    color: $color-texto !important;
    transition: $trans;
    &:hover {
      color: $color-principal !important;
      transition: $trans;
    }
  }
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }
  }
  &__copy {
  }

  &__priv {
    color: $color-texto;
    text-decoration: none;
    &:hover {
      color: $color-gris-03 !important;
    }
  }

  &__nav {
  }
  &__menu {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    .menu-item {
      margin-right: 1.5rem;
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 767px) {
      margin-top: 0.5rem;
    }
  }
}
