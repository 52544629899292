.wpcf7,
.newsletter__form {
  margin: 0;
  @media screen and (max-width: 991px) {
    margin-top: 1.5rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    font-weight: 500;
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 0.2rem !important;
  }

  .form-control {
    width: 100%;
    padding: 0.575rem 0.75rem;
    font-size: 0.85rem;
    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
    option {
      color: rgba(0, 0, 0, 0.3) !important;
      &:checked {
        color: rgba(0, 0, 0, 0.3) !important;
      }
    }
  }

  .wpcf7-select,
  .wpcf7-file,
  .your-cv {
    color: rgba(0, 0, 0, 0.3) !important;
  }

  .wpcf7-not-valid-tip {
    font-size: 12px;
    color: red;
  }
}

.wpcf7-acceptance {
  font-size: 0.6rem;
  color: $color-gris-02;
  color: rgba(0, 0, 0, 0.4);
  a {
    color: rgba(0, 0, 0, 0.4);
    &:hover {
      color: $color-principal;
    }
  }

  .wpcf7-list-item {
    // margin-left: 0;
  }
}

.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.5em 1em;
  border-radius: 8px;
}

.wpcf7-mail-sent-ok {
  border: 2px solid #398f14;
  background-color: #398f14;
  color: $color-background;
}

.wpcf7-mail-sent-ng {
  border: 2px solid #ff0000;
  background-color: #ff0000;
  color: $color-background;
}

.wpcf7-spam-blocked {
  border: 2px solid #ffa500;
  background-color: #ffa500;
}

.wpcf7-acceptance-missig {
  border: 2px solid #ffa500 !important;
  background-color: #ffa500 !important;
}

.bookly-form {
  //border: 1px solid $color-dark;
  background-color: $color-blanco;
  padding: 2rem;
  box-shadow: $box-shadow;
}

body:not(.page-template-page-contacto) {
  .grecaptcha-badge {
    display: none;
  }
}


.grecaptcha-badge{
  bottom: 100px !important;
}