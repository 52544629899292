.logosCv {
  padding: $font-size 0;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $font-size * 0.5;
  }

  &__logos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $font-size;
  }

  &__img {
    width: 124px;
    height: auto;
    img{
      width: 100%;
      height: auto;
    }
  }

  &__description {
    font-size: $font-size-smaller;
    text-align: center;
  }
}
