.servicios {
  &__content {
  }

  &__description {
    font-size: $font-size-big;
    color: $color-gris-03;
    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
    p {
      margin-bottom: 0;
    }
  }

  &__lista {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
      @include tag-principal;
    }
  }

  &__item {
  }
  &__icono {
  }
  &__descripcion {
  }
}

.extra {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__titulo {
    font-size: 2.1875rem;
    font-weight: bold;
    color: $color-dark;
    line-height: 1.1;
    margin-bottom: 1rem;
    font-family: $typo-alternativa;
    @media screen and (max-width: 991px) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.4rem;
    }
  }

  &__descripcion {
  }

  &__archivo {
    display: flex;
  }

  &__archivo-titulo {
    align-self: center;
    margin-right: 1rem;
  }

  &__archivo-descargar {
  }

  &__archivo-thumb {
    img {
      width: 200px;
      height: auto;
    }
  }
}

.precios {
  background-color: $color-background-light;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    //padding: 2rem;
    @media screen and (max-width: 991px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__zona {
    width: 100%;
    margin-bottom: 3rem;
    background-color: $color-background-light;
    background-color: $color-blanco;
    padding: 2rem;
    @media screen and (max-width: 767px) {
      padding: 1rem;
    }
  }

  &__servicio {
    color: $color-dark;
  }

  &__item {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    background-color: $color-blanco;
    @media screen and (max-width: 767px) {
      padding: 0.5rem;
    }
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__clientes {
    color: $color-principal;
  }

  &__tabla {
    width: 100%;
    .col-tarifa {
      width: 30%;
    }
    .col-descripcion {
      width: 60%;
    }
    .col-precio {
      width: 10%;
    }
  }

  &__tarifa {
  }

  &__precio {
  }

  &__descripcion {
  }

  &__extra {
  }

  &__vigencia {
  }

  &__nota {
    margin: 1rem 0;
    padding: 1rem;
    background-color: $color-principal;
    color: $color-blanco;
    @media screen and (max-width: 991px) {
      font-size: 0.875rem;
    }
  }
  p {
    margin-bottom: 0;
  }
}

.table-responsive {
  width: 100%;
}

.cartas {
  &__content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 3rem;
  }

  &__departamento {
  }

  &__titulo {
  }

  &__servicio {
    margin-bottom: 2rem;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__descripcion {
  }

  &__archivos {
    display: flex;
    justify-content: flex-start;
  }

  &__archivo {
    margin-right: 2rem;
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }

  &__archivo-titulo {
    margin-bottom: 0.5rem;
  }

  &__archivo-descargar {
  }
}
