/* SCSS Botones
--------------------------------------------------- */

.boton {
  padding: 0.8rem 1.5rem;
  display: inline-flex;
  text-decoration: none;
  border: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  transition: $trans;
  text-transform: uppercase;
  font-size: 0.9rem;
  border-radius: 4px;
  &:hover {
    transition: $trans;
  }
}

.boton--principal {
  background-color: $color-principal;
  color: $color-blanco;
  &:hover {
    background-color: darken($color: $color-principal, $amount: 10%);
    color: $color-blanco;
  }
}

.boton--dark {
  background-color: $color-dark;
  color: $color-blanco;
  &:hover {
    background-color: lighten($color: $color-dark, $amount: 10%);
    color: $color-blanco;
  }
}

.boton--secundario {
  background-color: $color-secundario;
  color: $color-blanco !important;
  &:hover {
    background-color: darken($color: $color-secundario, $amount: 10%);
    color: $color-blanco !important;
  }
}
.boton--full {
  width: 100%;
}

.boton--sm {
  padding: 0.4rem 1rem;
}

.boton--center {
  width: 9rem !important;
  margin: 0 auto;
  display: flex;
}
