.entorno {
  &__container {
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 6rem;
    margin-bottom: 3rem;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 0;
      align-items: flex-start;
    }
  }

  &__descripcion {
  }

  &__galeria {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    gap: 1rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 0.5rem;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 2px;
    }
  }

  &__galeria-item {
    &:nth-child(5) {
      @media screen and (min-width: 992px) {
        grid-column: 2 / span 2;
        grid-row: 1 / span 2;
      }
      @media screen and (min-width: 768px) and (max-width: 991px) {
        grid-column: 1 / span 2;
        grid-row: 3 / span 2;
      }
    }
  }

  &__galeria-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
