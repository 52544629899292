.room-data {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
  &__item {
    background: $color-background-light;
    border: 1px dashed $color-principal;
    padding: 0.5rem 1.4rem;
    border-radius: 50px;
    display: flex;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: $font-size-small;
  }

  &__icon {
    margin-right: $font-size-smaller;
  }

  &__content {
    color: $color-principal;
  }
}

.room-specs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: $color-background-light;
    border-radius: $border-radius;
    padding: 0.5rem 1.4rem 0.5rem 0.5rem;
    margin-right: 5px;
    margin-bottom: 5px;
    max-width: 300px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &__icon {
    border-radius: $border-radius;
    min-width: 80px;
    height: 80px;
    background: $color-background;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    @media screen and (max-width: 767px) {
      min-width: 60px;
      height: 60px;
    }
    @media screen and (max-width: 767px) {
      margin-right: 0.5rem;
      min-width: 50px;
      height: 50px;
    }
    img {
      width: 48px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 24px;
      }
    }
  }

  &__description {
    color: $color-principal;
    align-self: center;
    line-height: 1.3;
    @media screen and (max-width: 575px) {
      font-size: $font-size-small;
    }
  }
}

.room-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.1875rem;
  margin-bottom: 1rem;
  @include pad-l-r;
  @media screen and (min-width: 1400px) {
    @include max-w-1700;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 2px;
  }

  &__item {
    &:nth-child(1) {
      @media screen and (min-width: 992px) {
        grid-column: 3 / 5;
        grid-row: 1 / 3;
      }
    }

    &:nth-child(10) {
      @media screen and (min-width: 992px) {
        grid-column: 2 / span 2;
        grid-row: 3 / span 2;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media screen and (max-width: 767px) {
      margin-bottom: 2px;
    }
  }
}

.mas-habitaciones {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 6rem 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }
  &__content {
    text-align: center;
  }

  &__item {
    @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
    }
  }

  &__picture {
    margin-bottom: 1rem;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    text-align: center;
    font-size: 1.5rem;
    font-family: $typo-alternativa;
    letter-spacing: -1px;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }
  &__data {
    display: inline-flex;
    justify-content: center;
    font-size: $font-size-small;
    background-color: $color-principal;
    color: $color-background;
    padding: 4px 1rem;
    border-radius: 50px;
    i {
      margin-right: 10px;
    }
  }

  &__icon {
  }

  &__content {
  }
}
