@mixin pad-l-r {
  padding-left: 1rem;
  padding-right: 1rem;
}

@mixin max-w-1700 {
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  @include pad-l-r;
}

@mixin tag-principal {
  font-size: 0.875rem;
  border-radius: 4px;
  background-color: $color-principal;
  color: $color-blanco !important;
  text-decoration: none;
  padding: 0.3rem 0.7rem;
  box-shadow: $box-shadow;
}

@mixin lista-tags {
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  li {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    a {
      @include tag-principal;
    }
  }
}

@mixin lista-tags-vertical {
  padding-left: 0;
  list-style: none;
  display: block;
  li {
    margin-bottom: 0.5rem;
    a {
      @include tag-principal;
    }
  }
}
