.faq {
  padding: $sp-1 0;
  &__content {
    // display: grid;
    // grid-template-columns: 1fr 2fr;
    // gap: $sp-1;
    // border: 1px solid $color-gris-02;
    // border-radius: $border-radius;
  }

  .accordion {
    display: flex;
    flex-direction: column;
    gap: $font-size;
  }

  .accordion-item {
    &:nth-child(1) .accordion-button {
      border-top-width: 1px;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
    &:nth-last-child(1) .accordion-button {
      border-bottom-width: 1px !important;
      border-bottom-left-radius: $border-radius !important;
      border-bottom-right-radius: $border-radius !important;
    }
    &:nth-last-child(1) .accordion-collapse {
      border-bottom: none;
    }
  }

  .accordion-button {
    text-align: left;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    font-weight: bold;
    border: 1px solid $color-gris-01;
    border-radius: $border-radius;
    background-color: $color-background-light;
    span {
      margin-right: $sp-2;
    }
    &::after {
      padding-left: 1.5rem;
    }
  }

  .accordion-button:not(.collapsed) {
    color: $color-texto;
    background-color: $color-background-light;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(180deg);
  }

  .nav-link {
    width: 100%;
    text-align: left;
    border-radius: 0;
    border: 1px solid $color-gris-02;
    font-family: $typo-secundaria;
    color: $color-negro;
    &.active {
      border-color: $color-negro;
      background-color: $color-principal;
    }
    &:hover {
      border-color: $color-negro;
    }
  }

  .tab-pane-titulo {
    font-size: $font-size-bigger;
    font-family: $typo-secundaria;
    margin-bottom: $sp-1;
  }

  .accordion-body p {
    color: $color-dark;
  }
}

#nav-tab-faq {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
