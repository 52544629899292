.cta {
  // background: url("../assets/site/placeholder.jpg") center center;
  background-size: cover;
  background: $color-background-light;
  padding: 8rem 0;
  position: relative;

  @media screen and (max-width: 991px) {
    padding: 6rem 0;
  }
  @media screen and (max-width: 575px) {
    padding: 3rem 0;
  }

  &__container {
    position: relative;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 575px) {
      align-items: flex-end;
    }
  }

  &__content {
    text-align: center;
  }
  &__pre {
    color: $color-principal;
    font-weight: bold;
    margin-top: 0;
    @media screen and (max-width: 767px) {
      font-size: $font-size;
    }
  }
  &__title {
    font-family: $typo-alternativa;
    color: $color-dark;
    font-weight: bold;
    font-size: 3.125rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }

  &__motor {
    margin-top: 1.5rem;
    max-width: 1320px;
  }
}
