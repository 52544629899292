body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-gris-04;
  font-weight: $font-weight;
}

.container {
  &--max {
    max-width: 1450px;
  }
}

a {
  text-decoration: none;
  color: $color-principal;
  transition: $trans;
  &:hover {
    color: $color-dark;
    transition: $trans;
  }
}

.menu-visible {
  overflow-y: hidden;
}

.post-body {
  a {
    color: $color-principal;
    transition: $trans;
    &:hover {
      color: $color-dark;
      transition: $trans;
    }
  }
}

.mvl {
  display: none !important;
  @media screen and (max-width: 991px) {
    display: block;
  }
}

.desktop {
  display: none;
  @media screen and (min-width: 992px) {
    display: block;
  }
}

.test {
  background-color: #00293b;
  padding: 6rem 0;

  &__content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 3rem;
  }
}

:focus-visible::-moz-focus-inner {
  border-color: $color-gris-03;
}
