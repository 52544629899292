.directorio {
  &__content {
  }

  &__bloque {
    display: flex;
    flex-direction: column;
    &__titulo {
      font-family: $typo-alternativa;
      font-size: 2.1875rem;
      font-weight: bold;
      color: $color-dark;
      line-height: 1.1;
      margin-bottom: 1rem;
      @media screen and (max-width: 991px) {
        font-size: 1.8rem;
      }
      @media screen and (max-width: 575px) {
        font-size: 1.4rem;
      }
    }
  }

  &__servicio {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 3rem;
    margin-bottom: 3rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
    &__item {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      border: 1px solid $color-gris-01;
      border-radius: 1rem;
      box-shadow: $box-shadow;
    }
    &__titulo {
      font-family: $typo-alternativa;
      font-size: 1.8rem;
      font-weight: bold;
      color: $color-dark;
      line-height: 1.1;
      margin-bottom: 1rem;
      @media screen and (max-width: 991px) {
        font-size: 1.6rem;
      }
      @media screen and (max-width: 575px) {
        font-size: 1.4rem;
      }
      &--sub {
        font-size: 1.4rem;
        @media screen and (max-width: 991px) {
          font-size: 1.2rem;
        }
        @media screen and (max-width: 575px) {
          font-size: 1.1rem;
        }
      }
    }
    &__subtitulo {
      p {
        margin-bottom: 0;
      }
    }
    &__datos {
      display: flex;
      flex-direction: column;
      //align-items: flex-start;
      justify-content: center;
    }

    &__dato {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1rem;
      }
    }
    &__info {
      margin-right: auto;
      @media screen and (max-width: 480px) {
        margin-right: 0;
      }
    }
    &__detalle {
      @include tag-principal;
    }
  }
}
