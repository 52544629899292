.hero {
  overflow: hidden;
  height: 960px;
  position: relative;
  //top: 6rem;
  @media screen and (max-width: 767px) {
    height: 540px;
    //top: 4.5rem;
  }
  &__video {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 5rem;
    @media screen and (max-width: 767px) {
      padding-bottom: 1rem !important;
    }
  }

  &__title {
    z-index: 2;
    color: $color-blanco;
    font-size: 4.2rem;
    font-weight: bold;
    //margin-bottom: 1.5rem;
    line-height: 1;
    font-family: $typo-alternativa;
    letter-spacing: -1px;
    margin-bottom: 2rem;
    @media screen and (max-width: 1440px) {
      font-size: 4rem;
    }
    @media screen and (max-width: 991px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
}

.covid {
  padding: 2rem 0;
  &__content {
    text-align: center;
    font-size: $font-size-small;
  }
}

.motivo {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
  justify-items: center;
  @media (max-width: 767px) {
    gap: 0.5rem;
  }
  &--4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    .motivo__item {
      max-width: 100%;
    }
    .motivo__icono {
      height: 60px;
    }
  }
  &--5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    .motivo__item {
      max-width: 100%;
    }
    .motivo__icono {
      height: 60px;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  &__item {
    text-align: center;
    padding: 1rem 1rem 1rem;
    border-radius: 4px;
    max-width: 14.5rem;
    @media (max-width: 767px) {
      padding: 0.5rem;
      border-radius: 8px;
    }
    &--link {
      &:hover {
        background-color: $color-gris-02;
      }
    }
  }

  &__icono {
    height: 40px;
    width: auto;
    margin-bottom: 1rem;
    opacity: 0.8;
    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr 1fr;
      height: 30px;
      margin-bottom: 0.5rem;
    }
  }
  &__content {
    color: $color-principal;
    font-size: $font-size;
    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
  }
}

.wprs_unslider {
  @media screen and (max-width: 1440px) {
    margin: 0 auto !important;
    max-width: 90%;
  }
  @media screen and (max-width: 1280px) {
    max-width: 85%;
  }
  @media screen and (max-width: 991px) {
    max-width: 75%;
  }
  @media screen and (max-width: 767px) {
    max-width: 80%;
  }
  @media screen and (max-width: 575px) {
    max-width: 100%;
  }
}
