.header {
  color: $color-texto;
  background-color: $color-background;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  transition: $trans-2;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  box-shadow: $box-shadow;
  @media screen and (max-width: 1440px) {
    padding: 1rem 1.2rem;
  }
  @media screen and (max-width: 1280px) {
    padding: 1rem 1rem;
  }
  @media screen and (max-width: 767px) {
    padding: 0.5rem 1rem;
  }

  & > * {
    display: flex;
    align-items: center;
  }

  &--page {
    background-color: $color-background-light;
  }

  &__logo {
    display: inline-flex;
    width: auto;
    transition: $trans;
    margin-right: 2rem;
    @media screen and (max-width: 1280px) {
      margin-right: 1rem;
    }
    img {
      width: 160px;
      height: auto;
      @media screen and (max-width: 1440px) {
        width: 136px;
      }
      @media screen and (max-width: 1280px) {
        width: 128px;
      }
      @media screen and (max-width: 767px) {
        width: 110px;
      }
    }
  }

  &__left {
    //align-self: center;
  }

  &__right {
    align-self: center;
    display: inline-flex;
    margin-left: auto;
  }

  &__action {
    display: inline-flex;
    height: auto;
    margin-right: 2rem;
  }

  &__wpml {
    @media screen and (max-width: 1150px) {
      display: none;
    }
  }

  &__burger {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    @media screen and (min-width: 1151px) {
      display: none;
    }
    @media screen and (max-width: 1150px) {
      display: inline-block;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.5rem;
      margin-right: 0;
    }
    a {
      color: $color-texto;
      i {
        font-size: 2.5rem;
        transition: $trans;
      }
      &:hover {
        color: $color-dark;
      }
    }
  }
  &__nav-up {
    top: -7rem;
    transition: $trans-2;
  }
  &__nav-down {
  }
  &__visible {
    top: 0;
    transition: $trans-2;
  }
  &__oculto {
    top: -7rem;
    transition: $trans-2;
  }
}

.header-motor {
  padding: 0.8rem 2rem;
  justify-content: center;
  @media screen and (max-width: 1440px) {
    padding: 0.8rem 1.2rem;
  }
  @media screen and (max-width: 1280px) {
    padding: 0.8rem 1rem;
  }
  @media screen and (max-width: 767px) {
    padding: 0.5rem 1rem;
  }
  .header__logo {
    margin-right: auto;
    @media screen and (max-width: 575px) {
      margin-right: 0;
    }
  }
  .header__reservas {
    @media screen and (max-width: 1440px) {
      margin: 0 1.5rem;
    }
    @media screen and (max-width: 991px) {
      margin-right: 2rem;
    }
    @media screen and (max-width: 575px) {
      margin: 0 auto;
    }
  }
  .menu-contacto {
    margin-left: auto;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  .header__burger {
    line-height: 1.4;
    @media screen and (min-width: 1151px) {
      display: inline-block;
    }
    @media screen and (max-width: 1150px) {
      display: inline-block;
    }
  }
}

.menu-contacto {
  display: flex;
  @media screen and (max-width: 1150px) {
    margin-right: 1rem;
  }
  &__item {
  }
  &__logo {
    margin: auto;
    width: 34px;
    height: auto;
    @media screen and (max-width: 575px) {
      width: 28px;
    }
    &--whats {
      width: 40px;
      fill: #4ca287 !important;
      @media screen and (max-width: 575px) {
        width: 32px;
      }
    }
    &--tel {
      padding: 1px;
    }
  }
}
.menu-overlay {
  width: 100%;
  height: 100vh;
  background-color: $color-secundario;
  position: fixed;
  top: 0;
  overflow: scroll;
  z-index: 40;
  list-style: none;

  &--oculto {
    display: none;
    transition: $trans;
  }
  &--activo {
    display: flex;
    flex-direction: column;
    transition: $trans;
    z-index: 1000;
  }
  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }
  &__cerrar {
    z-index: 2;
    position: relative;
    font-size: 3rem;
    line-height: 0;
    padding-right: 2.65rem;
    padding-top: 1.25rem;
    color: $color-dark;
    text-align: right;
    @media screen and (max-width: 1280px) {
      padding-right: 1.8rem;
      padding-top: 1.25rem;
    }
    @media screen and (max-width: 575px) {
      padding-right: 1rem;
      padding-top: 1rem;
    }
    a {
      color: $color-dark;
      &:hover {
        color: lighten($color-dark, $amount: 10);
      }
    }
    @media screen and (max-width: 575px) {
      font-size: 3rem;
    }
  }
  &__menu {
    width: 100%;
    padding: 0 1.5rem;
    display: flex;
    justify-content: center;
    text-align: center;
    .sub-menu {
      display: flex;
      justify-content: center;
    }
  }
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1rem;
    li.menu-item {
      margin-bottom: 0.7rem;
      & > a {
        font-size: 2rem;
        font-weight: bold;
      }
      ul.sub-menu {
        list-style: none;
        display: flex;
        justify-content: center;
        padding-left: 0;
        flex-wrap: wrap;
        li {
          margin: 0 5px 5px 0px; //si hay más de un 'li'
          margin: 0;
          a {
            background-color: $color-dark;
            color: $color-background !important;
            font-size: 1.5rem;
            padding: 4px 8px;
            border-radius: 4px;
            display: inline-block;
            font-weight: 400;
            &:hover {
              color: $color-alternativo !important;
            }
          }
        }
      }
    }
  }

  &__contacto {
    display: flex;
    justify-content: center;
  }
  &__item {
    display: flex;
    justify-self: center;
  }
  &__logo {
    margin: auto;
    width: 34px;
    height: auto;
    @media screen and (max-width: 575px) {
      width: 28px;
    }
    &--webcam {
      margin-right: 1.5rem;
    }
    &--whats {
      width: 40px;
      fill: #4ca287 !important;
      margin-right: 1.5rem;
      @media screen and (max-width: 575px) {
        width: 32px;
      }
    }
    &--tel {
      padding: 1px;
    }
  }
  &__wpml {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    .wpml-ls-link img {
      width: 32px !important;
      border: 1px solid $color-gris-02;
      border-radius: 4px;
    }
  }
}

.menu-overlay__boton:nth-last-child(1) {
  margin-top: 1rem;
}

.sticky-motor {
  &--oculto {
    display: none;
    transition: $trans-2;
  }
  &--visible {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transition: $trans-2;
  }
  .mvl {
    @media screen and (max-width: 991px) {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}

.single-oferta .sticky-motor {
  display: none;
  .mvl {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
}
