.newsletter {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    width: 100%;
    max-width: 24rem;
    background-color: $color-gris-01;
    padding: 2rem;
    border-radius: 1rem;
  }

  &__titulo {
    color: $color-dark;
    font-weight: bold;
    font-size: $font-size-bigger;
    margin-bottom: 1.25rem;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: $font-size-big;
    }
  }

  &--widget {
    .newsletter__titulo {
      font-size: 1rem;
      color: $color-secundario;
      text-transform: uppercase;
      font-weight: 500;
    }
    .newsletter__content {
      background-color: transparent;
      padding-top: 0;
    }
    .newsletter__form {
      .form-group {
        margin-bottom: 0.875rem;
      }
      .form-control {
        padding: 0.4rem 0.75rem;
        font-size: 0.8rem;
      }
      .form-check {
        padding-left: 2rem;
        font-size: $font-size-smaller;
      }
      .form-check-label {
        color: $color-blanco;
      }
    }
  }

  .bv-form .help-block {
    color: red;
  }

  .form-group {
    position: relative;
  }

  .form-control-feedback {
    position: absolute;
    top: 6px;
    right: 24px;
  }

  .form-check {
    padding-left: 2.5rem;
    font-size: $font-size-smaller;
    .form-control-feedback {
      display: none !important;
    }
  }

  input[type="submit"]:disabled {
    background-color: $color-secundario;
    opacity: 0.3;
  }

  .bi-ok::before {
    color: #398f14;
  }
  .bi-ko::before {
    color: #ff0000;
  }
  .bi-refresh::before {
    color: #ffa500;
  }
}
