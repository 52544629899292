/* SCSS Headers
--------------------------------------------------- */

.title {
  margin-bottom: 2rem;
  @media screen and (max-width: 767px) {
    margin-bottom: 1rem;
  }
  &--center {
    text-align: center;
  }
  &__h {
    font-size: 2.5rem;
    font-family: $typo-alternativa;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    @media screen and (max-width: 1280px) {
      font-size: 2rem;
    }
  }

  &__subtitle {
    font-size: 1.3rem;
    color: $color-principal;
    @media screen and (max-width: 767px) {
      font-size: 1.1rem;
    }
  }
}

.titulos {
  &__pretitulo {
    color: $color-principal;
    font-weight: bold;
    margin-top: 0;
    @media screen and (max-width: 767px) {
      font-size: $font-size;
    }
  }
  &__titulo {
    font-family: $typo-alternativa;
    color: $color-dark;
    font-weight: bold;
    font-size: 3.125rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
    }
  }
  &--centrado {
    text-align: center;
  }
  &--mar-b1 {
    margin-bottom: 3rem;
  }
}
