.contacto {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 3rem;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }

  &__col1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0rem;
      margin-bottom: 3rem;
    }
  }

  &__descripcion {
    margin-bottom: 3rem;
    font-size: 1.2rem;
    color: $color-gris-03;
    @media screen and (max-width: 991px) {
      margin-bottom: 2rem;
    }
  }

  &__indicaciones-list {
    display: flex;
  }

  &__indicaciones-item {
    margin-right: 1rem;
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    @media screen and (max-width: 991px) {
      margin-bottom: 1rem;
    }
  }

  &__titulo {
    font-size: $font-size-big;
    font-weight: bold;
    color: $color-dark;
  }

  &__movil,
  &__telefono,
  &__fax,
  &__email {
    color: $color-gris-03;
  }

  &__movil {
  }

  &__telefono {
  }

  &__telefono-reservas {
  }

  &__whatsapp {
    margin-bottom: 0.5rem;
  }

  &__fax {
  }

  &__email {
  }

  &__col2 {
  }

  &__mapa {
    iframe {
      width: 100%;
    }
  }
}
