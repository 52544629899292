.feat {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  @include pad-l-r;
  @media screen and (min-width: 1400px) {
    grid-template-columns: 1.5fr 1fr;
    @include max-w-1700;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 3rem;
  }

  &--inverted {
    @media screen and (min-width: 1400px) {
      grid-template-columns: 1fr 1.5fr;
      @include max-w-1700;
    }
    @media screen and (min-width: 992px) {
      .feat__picture {
        grid-column: 2 / 3;
      }
      .feat__content {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
    }
  }
  &__picture {
    @media screen and (max-width: 767px) {
      margin-bottom: 0.5rem;
    }

    position: relative;

    img {
      width: 100%;
      height: 600px;
      object-fit: cover;
      @media screen and (max-width: 991px) {
        height: 300px;
      }
      @media screen and (max-width: 575px) {
        height: 250px;
      }
    }
    iframe {
      width: 100%;
      height: 600px;
      object-fit: cover;
      &.webcam {
        @media screen and (max-width: 991px) {
          height: 420px;
        }
        @media screen and (max-width: 767px) {
          height: 260px;
        }
      }
      &.meteo {
        @media screen and (max-width: 991px) {
          height: 655px;
        }
        @media screen and (max-width: 767px) {
          height: 526px;
        }
      }
    }
  }

  &__picture-badge {
    position: absolute;
    top: 0;
    left: 0;
    width: fit-content;
    height: auto;
    background-color: $color-secundario;
    padding: 1.5rem;
    color: $color-dark;
    font-weight: bold;
    font-size: 1.2rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 3rem;
    @media screen and (max-width: 991px) {
      padding: 1.5rem 0;
    }
    @media screen and (max-width: 767px) {
      padding: 0.2rem 0;
    }
  }

  &__pre {
    font-size: $font-size;
    text-transform: uppercase;
    color: $color-principal;
    font-weight: bold;
    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
  }

  &__title {
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    line-height: 1;
    color: $color-dark;
    font-family: $typo-alternativa;
    letter-spacing: -1px;
    a {
      color: $color-dark;
      &:hover {
        color: lighten($color: $color-dark, $amount: 10%);
      }
    }
    @media screen and (max-width: 1440px) {
      font-size: 4rem;
    }
    @media screen and (max-width: 991px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  &__description {
    font-size: $font-size-big;
    color: $color-gris-03;
    @media screen and (max-width: 991px) {
      font-size: $font-size-small;
    }
    p {
      margin-bottom: 0;
    }
  }

  &__meteo {
    margin-top: 1.5rem;
  }

  &__actions {
    margin-top: 2rem;
    @media screen and (max-width: 991px) {
      margin-top: 1.5rem;
    }
    @media screen and (max-width: 767px) {
      margin-top: 1rem;
    }
  }

  &__features {
    margin-top: 2rem;
  }
}

.page-template-page-habitacion .feat {
  margin-bottom: 0;
}

.feat-cols {
  display: grid;
  gap: 2rem;
  margin-bottom: 3rem;
  &--2 {
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 5px;
      margin-bottom: 3rem;
    }
  }
  &--3 {
    grid-template-columns: 1fr 1fr 1fr;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 5px;
      margin-bottom: 3rem;
    }
  }

  &__item {
    height: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 2rem;
    @media screen and (max-width: 991px) {
      height: 350px;
    }
    @media screen and (max-width: 767px) {
      height: 300px;
    }
  }

  &__content {
    position: relative;
    z-index: 3;
    text-align: center;
    margin-bottom: 1rem;
    @media screen and (max-width: 575px) {
      margin-bottom: 0;
    }
  }

  &__title {
    color: $color-blanco;
    font-size: 3rem;
    font-weight: bold;
    font-family: $typo-alternativa;
    @media screen and (max-width: 991px) {
      font-size: 2rem;
    }
  }

  &__subtitle {
    font-weight: 700;
    color: $color-secundario;
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  &__overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 30%);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    @media screen and (max-width: 991px) {
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%);
    }
  }

  &__img {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.feat-ofertas {
  display &__content {
  }

  &__list {
    display: grid;
    gap: 2rem;
    margin-bottom: 3rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: 5px;
      margin-bottom: 3rem;
    }
  }

  &__item {
    height: 600px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 2rem;
    @media screen and (max-width: 991px) {
      height: 350px;
    }
    @media screen and (max-width: 767px) {
      height: 300px;
    }
  }

  &__contenido {
    position: relative;
    z-index: 3;
    text-align: center;
    margin-bottom: 1rem;
    @media screen and (max-width: 575px) {
      margin-bottom: 0;
    }
  }

  &__title {
    color: $color-blanco;
    font-size: 3rem;
    font-weight: bold;
    font-family: $typo-alternativa;

    @media screen and (max-width: 991px) {
      font-size: 2rem;
    }
  }

  &__subtitle {
    font-weight: 700;
    color: $color-secundario;
    text-transform: uppercase;
    font-size: 1rem;
  }

  &__overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    @media screen and (max-width: 991px) {
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%);
    }
  }

  &__img {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.feat-fw {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 3rem;
  position: relative;
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.2);
  }
  &__content {
    position: relative;
    text-align: center;
    margin: 0 auto;
    width: 60%;
    height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $color-blanco;
    @media screen and (max-width: 1440px) {
      width: 65%;
    }
    @media screen and (max-width: 1280px) {
      width: 75%;
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      height: 400px;
    }
  }

  &__title {
    font-size: 4rem;
    font-weight: bold;
    font-family: $typo-alternativa;

    @media screen and (max-width: 767px) {
      font-size: 2.5rem;
    }
  }

  &__subtitle {
    @media screen and (max-width: 767px) {
      font-size: 1.1rem;
    }
  }

  &__actions {
    margin-top: 3rem;
    @media screen and (max-width: 991px) {
      margin-top: 1.5rem;
    }
    @media screen and (max-width: 767px) {
      margin-top: 1rem;
    }
  }
}

.feat-gallery {
  margin-bottom: 6rem;
  @include pad-l-r;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1400px) {
    @include max-w-1700;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: 1fr;
    gap: 1rem;
    @media screen and (max-width: 991px) {
      gap: 0.5rem;
      grid-auto-rows: unset;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 2px;
    }
  }

  &__content {
    align-self: center;
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    width: 90%;
    @media screen and (max-width: 767px) {
      grid-column: 1 / span 1;
    }
  }

  &__header {
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    line-height: 1;
    color: $color-dark;
    font-family: $typo-alternativa;
    letter-spacing: -1px;
    a {
      color: $color-dark;
      &:hover {
        color: lighten($color: $color-dark, $amount: 10%);
      }
    }
    @media screen and (max-width: 991px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  &__title {
    font-family: $typo-alternativa;
    font-size: 2.1875rem;
    font-weight: bold;
    color: $color-dark;
    line-height: 1.1;
    margin-bottom: 1rem;
    @media screen and (max-width: 991px) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.4rem;
    }
  }

  &__description {
    font-size: $font-size-big;
    color: $color-principal;
    max-width: 70%;
  }

  &__gallery {
  }

  &__gallery-item {
    &:nth-child(2) {
      @media screen and (min-width: 992px) {
        grid-column: 3 / span 2;
        grid-row: 1 / span 2;
      }
    }

    &:nth-child(10) {
      @media screen and (min-width: 992px) {
        grid-column: 2 / span 2;
        grid-row: 3 / span 2;
      }
    }

    &:nth-child(14) {
      @media screen and (min-width: 992px) {
        grid-column: 1 / span 2;
        grid-row: 5 / span 2;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      grid-column: span 2;
      grid-row: span 2;
    }
  }

  &__gallery-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.page-template-page-instalaciones {
  @media screen and (max-width: 991px) {
    .feat-gallery {
      margin-top: 0rem;
      margin-bottom: 3rem;
    }
    .feat-gallery:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
}

.feat-citas {
}

.feat-info {
  margin-top: 3rem;
  margin-bottom: 3rem;
  @include pad-l-r;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (min-width: 1400px) {
    grid-template-columns: 1fr 1.5fr;
    @include max-w-1700;
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  &__title {
    padding-right: 3rem;
    font-size: 2.1875rem;
    font-weight: bold;
    color: $color-dark;
    line-height: 1.1;
    margin-bottom: 1rem;
    font-family: $typo-alternativa;
    @media screen and (max-width: 991px) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.6rem;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__item {
    color: $color-gris-03;
    margin-bottom: 0.5rem;
    a {
      color: $color-gris-03;
    }
  }
}

.feat-contenido {
  margin: 3rem 0 3rem 0;
  @include pad-l-r;
  @media screen and (min-width: 1400px) {
    @include max-w-1700;
  }
  iframe {
    width: 100%;
    height: auto;
    min-height: 300px;
  }

  &__header {
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    line-height: 1;
    color: $color-dark;
    font-family: $typo-alternativa;
    letter-spacing: -1px;
    a {
      color: $color-dark;
      &:hover {
        color: lighten($color: $color-dark, $amount: 10%);
      }
    }
    @media screen and (max-width: 991px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  &__title {
    font-family: $typo-alternativa;
    font-size: 3rem;
    font-weight: bold;
    color: $color-dark;
    line-height: 1.1;
    margin-bottom: 1rem;
    @media screen and (max-width: 991px) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: 575px) {
      font-size: 1.4rem;
    }
  }

  &__bloque {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 6rem;
    @media screen and (min-width: 1400px) {
      grid-template-columns: 1.5fr 1fr;
    }
    @media screen and (max-width: 1440px) {
      margin-bottom: 4rem;
    }
    @media screen and (max-width: 1280px) {
      margin-bottom: 3.5rem;
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 3rem;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      margin-bottom: 2rem;
      gap: 1rem;
    }

    img {
      width: 100%;
      height: 600px;
      object-fit: cover;
      @media screen and (max-width: 991px) {
        height: 360px;
      }
      @media screen and (max-width: 767px) {
        height: 240px;
      }
    }
    &--inverted {
      @media screen and (min-width: 1400px) {
        grid-template-columns: 1fr 1.5fr;
      }
      @media screen and (max-width: 767px) {
        img {
          order: 2 !important;
        }
        .feat-contenido__info {
          order: 3 !important;
        }
      }
      img {
        order: 3;
      }
      .feat-contenido__info {
        order: 2;
        padding-left: 0;
        padding-right: 3rem;
        @media screen and (max-width: 991px) {
          padding-right: 2rem;
        }
      }
    }

    &--11 {
      gap: 3rem;
      @media screen and (min-width: 1400px) {
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 991px) {
        gap: 2rem;
      }
      @media screen and (max-width: 767px) {
        gap: 1rem;
      }
      .feat-contenido__info {
        padding-left: 0;
      }
    }

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__info {
    padding-left: 3rem;
    align-self: center;
    @media screen and (max-width: 991px) {
      padding-left: 2rem;
    }
    @media screen and (max-width: 767px) {
      padding-left: 0;
    }

    ul.tags {
      @include lista-tags;
    }
  }
}
