.widget--page {
  @media screen and (max-width: 575px) {
    margin-bottom: 2rem !important;
  }
  .menu {
    list-style: none;
    padding-left: 0rem;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      @media screen and (max-width: 767px) {
        margin-right: 8px;
        margin-bottom: 8px;
      }
      a {
        @media screen and (max-width: 767px) {
          padding: 4px 8px;
          background-color: $color-gris-02;
          color: $color-background;
          display: inline-flex;
          border-radius: 2px;
        }
      }
    }
    li.current-menu-item {
      a {
        font-weight: bold;
      }
    }
  }
}

.widget {
  &__title {
    font-size: 1.1rem;
    color: $color-alternativo;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
  }
  ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    li {
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
      a {
        font-size: $font-size-small;
      }
    }
  }
}
