.grid-carta {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

.carta {
  margin-bottom: 1.5rem;
  @media screen and (min-width: 992px) {
    padding: 2rem;
    background-color: $color-background;
    box-shadow: $box-shadow;
    border-radius: 1rem;
  }
  &__familia {
    margin-bottom: 2rem;
    @media screen and (max-width: 767px) {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 4fr 1.5fr;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed rgba($color: #000000, $alpha: 0.2);
  }

  &__content {
    display: grid;
    grid-template-columns: 60px 1fr;
    gap: 1rem;
  }

  &__info {
    display: block;
    margin: auto 0;
  }

  &__titulo {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: $color-principal;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  &__do {
    font-size: $font-size-small;
    color: $color-gris-03;
  }

  &__uva {
    font-size: 0.9rem;
    color: $color-gris-03;
  }

  &__descripcion {
    p {
      margin-bottom: 0;
    }
  }

  &__figure {
    border: 1px solid rgba($color: #000000, $alpha: 0.1);
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      display: flex;
    }
  }

  &__picture {
    max-width: 100%;
    height: auto;
    max-height: 70px;
    @media screen and (max-width: 575px) {
      max-height: 50px;
    }
  }

  &__precio {
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: flex-end;
    sup {
      padding-left: 5px;
      top: -0.2em;
    }
  }

  &__precios {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__precios-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__precios-desc {
  }

  &__precios-precio {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    sup {
      padding-left: 5px;
      top: -0.2em;
    }
  }
}
